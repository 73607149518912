import React, { useState, useEffect } from "react";
// import { Form } from "react-bootstrap";
import Select from "react-select";
import $ from "jquery";
import _ from "lodash";
import { find } from "lodash";
import { navigate } from "@reach/router";
// import { navigate as gatsbyNavigate } from "gatsby";
import { bedroomsRange, propertySaleRentOptions, resiSalesPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, customStyles, priceminRangeResiRent, pricemaxRangeResiRent, sqftMinRange, sqftMaxRange, comPropertyType } from "../common/propertyUtils"; 

import SearchListBox from "../SearchResults/PredictiveSearch/search-multiarea";
import AreaListBox from "../SearchResults/PredictiveSearch/AreaList";
import "./BannerSearch.scss"

const SearchForm = (props) => {

    const clickHandle = (e) => {
        var handleElement = document.getElementById('filter-price-range');
        if(handleElement.classList.contains('active') ){
          handleElement.classList.remove('active');
          
        } else {
        if(e != undefined) {
          const li = e.target.closest('li');
          li.closest('ul').querySelectorAll('li').forEach(item => {
            if (item !== li) {
              item.classList.remove('active');
            }
          })
          e.target.closest('li').classList.toggle('active');
        }
      }
    }

    // 
    const [areaVal, setAreaVal] = useState('');
    const [minpriceVal, setMinPriceVal] = useState('');
    const [searchType, setSearchType] = useState(props?.alias?.toLowerCase() == "rent" ? "rent" : "buy");
    const [maxpriceVal, setMaxPriceVal] = useState('');
    const [propertyval, setPropertyVal] = useState('');
    const [bedVal, setBedVal] = useState('');
    const [minPriceList, setMinPriceList] = useState([]);
    const [maxPriceList, setMaxPriceList] = useState([]);
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
    const [selectedMinSqftValue, setSelectedMinSqftValue] = useState(0);
    const [selectedMaxSqftValue, setSelectedMaxSqftValue] = useState(0);
    const [minSqftVal, setMinSqftVal] = useState('');
    const [maxSqftVal, setMaxSqftVal] = useState(''); 

    // Multi area search
    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);
    // 

    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (_.isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }
    // Multi area search

    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price

    // Sales price list
    var minPrice = [];
    var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

    if (minPrice.length > 0) {
    } else {
        minPrice.push(priceminRangeResiSale[0])
    }

    if (maxPrice.length > 0) {
    } else {
        maxPrice.push(pricemaxRangeResiSale[0])
    }
    // Sales price list

      // Min Sqft
      const onChangeMinSqft = (e) => {
        setSelectedMinSqftValue(e.value);
    }
    // Min Sqft

    // Max Sqft
    const onChangeMaxSqft = (e) => {
        setSelectedMaxSqftValue(e.value);
    }
    // Max Sqft

     // Sqft list
     var minSqft = [];
     var maxSqft = sqftMaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinSqftValue)));
 
     sqftMinRange.map(items => parseInt(items.value) < parseInt(selectedMaxSqftValue) && minSqft.push(items));
 
     if (minSqft.length > 0) {
     } else {
        minSqft.push(sqftMinRange[0])
     }
 
     if (maxSqft.length > 0) {
     } else {
        maxSqft.push(sqftMaxRange[0])
     }
     // Sqft price list

    // 
    useEffect(() => {
        // 
        if (_.isEmpty(minPriceList)) {
            if(window.location.pathname.includes("sale")) {
                setMinPriceList(priceminRangeResiSale)
                setMaxPriceList(pricemaxRangeResiSale)
            } else {
                setMinPriceList(priceminRangeResiRent)
                setMaxPriceList(pricemaxRangeResiRent)
            }
        }
        //
        
        // Page when load field in text boxes will same
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("/")

        for (let vi = 1; vi <= pathUri.length; vi++) {
          // check for area
            if (typeof pathUri[vi] === "undefined") {
                continue
            }
    
            // Property type
            if (pathUri[vi].indexOf("type-") >= 0) {
                setPropertyVal(pathUri[vi].replace("type-", ""))
            }
            // Property type
        
            // Area
            if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-dubai") == -1)) {
                var areaArrfilt = (pathUri[vi].replace("in-", ""))
                if (areaArrfilt === "dubai") { areaArrfilt = "" }
                props.setAreaList(areaArrfilt.split("-and-"));

                var areaTag = areaArrfilt.split("-and-")
                var selectedAreaTag = []
            }
            // Area
        
            // Price
            if (
                pathUri[vi].indexOf("between-") >= 0 ||
                pathUri[vi].indexOf("above-") >= 0 ||
                pathUri[vi].indexOf("under-") >= 0
            ) {
                let priceFilt1 = pathUri[vi].split("above-")
                if (priceFilt1[1]) {
                    setMinPriceVal(priceFilt1[1])
                }
                let priceFilt2 = pathUri[vi].split("under-")
                if (priceFilt2[1]) {
                    setMaxPriceVal(priceFilt2[1])
                }
                let priceFilt3 = pathUri[vi].split("between-")
                if (priceFilt3[1]) {
                    let priceFilt4 = priceFilt3[1].split("-and-")
                    setMinPriceVal(priceFilt4[0])
                    setMaxPriceVal(priceFilt4[1])
                }
            }
            // Price
    
            // Bedrooms
            if (pathUri[vi].indexOf("-bedroom") >= 0) {
                setBedVal(pathUri[vi].replace("-bedroom", ""))
            }

            if (pathUri[vi].indexOf("-bedrooms") >= 0) {
                setBedVal(pathUri[vi].replace("-bedrooms", ""))
            }
            
            // if (pathUri[vi].indexOf("from-") >= 0) {
            //     let bedroomFilt1 = pathUri[vi].split("from-")
            //     if (bedroomFilt1[1]) {
            //     var splitbed1 = bedroomFilt1[1].split("-bedrooms")
            //     if (bedroomFilt1[1].indexOf("from-") <= 0) {
            //         setBedVal(splitbed1[0])
            //     }
            //     }
            // }
            // Bedrooms
    
        }
        // Page when load field in text boxes will same
    },[]);
    // 

    // Submit search
    const submitSearch = (e) => {

        // 
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var bedroomValue = bedVal ? bedVal : $(".bedroom-input input[type='hidden']").val();
        var buildingValue = propertyval ? propertyval : $(".property input[type='hidden']").val();
        let priceUrl = ""
        let sqftUrl = ""

        if (maxpriceVal || minpriceVal) {
            if (minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
                priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
            } else if ((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
                priceUrl = "under-" + maxpriceVal
            }
            else if (minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
                priceUrl = "above-" + minpriceVal
            }
        }

        if (minSqftVal || maxSqftVal) {
            if (minSqftVal && maxSqftVal && minSqftVal != "0" && maxSqftVal != "0") {
                sqftUrl = "sqft-area-" + minSqftVal + "-and-" + maxSqftVal
            } else if ((minSqftVal == '' || minSqftVal == "0") && maxSqftVal && maxSqftVal != "0") {
                sqftUrl = "maxsqft-" + maxSqftVal
            }
            else if (minSqftVal && (maxSqftVal == '' || maxSqftVal == "0") && minSqftVal != "0") {
                sqftUrl = "minsqft-" + minSqftVal
            }
        }

        var searchFilterResults = "";

        const pathname = window.location.pathname

        var uriStr = pathname.includes("holiday")
          ? "/holiday-homes/for-rent"
          : pathname.includes("rent")
          ? "/properties/for-rent"
          : pathname.includes("commercial")
          ? "/properties/commercial/for-sale"
          : "/properties/for-sale" 

        if(searchType === "rent" && pathname.includes("commercial")){
            uriStr = "/properties/commercial/for-sale";
        }else if(searchType === "rent"){
            uriStr =  "/properties/for-rent";
        } 

        if(areaValue) {
            searchFilterResults = uriStr + "/in-" + areaValue + "/";
        } else {
            searchFilterResults = uriStr + "/in-dubai/";
        }

        if(buildingValue){
            searchFilterResults += "type-" + buildingValue + "/";
        }
    
        if(bedroomValue && bedroomValue != "0" && bedroomValue > 1){
            searchFilterResults += bedroomValue + "-bedrooms/";
        } else if (bedroomValue && bedroomValue != "0" && bedroomValue > 0) {
            searchFilterResults += bedroomValue + "-bedroom/";
        }

        // if(bedroomValue && bedroomValue != "0"){
        //     searchFilterResults += "from-" + bedroomValue + "-bedrooms/";
        // }

        if(priceUrl){
            searchFilterResults += priceUrl +"/";
        }

        if(sqftUrl){
            searchFilterResults += sqftUrl +"/";
        }

        navigate(searchFilterResults);
    }
    // Submit search 
    return (
        <section className="search-form-wrapper home-banner-search-form">
            <div className="search-form-features d-flex align-items-center justify-content-between">
            <div className="search-buyrent-wrapper ms-5 d-xl-flex align-items-center d-none" onClick={e => clickHandle()}>
                    <Select
                        options={propertySaleRentOptions}
                        isSearchable={false}
                        placeholder={find(propertySaleRentOptions, ['label', (searchType === "buy" ? 'Buy' : 'Rent')])}
                        value={find(propertySaleRentOptions, ['label', (searchType === "buy" ? 'Buy' : 'Rent')])}
                        className={"select-control"}
                        classNamePrefix={"react-select"}
                        styles={customStyles}
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={(e) => setSearchType(e?.value)}
                        
                    />
                     <div class="search-divider"></div>
                </div>
                <div className="search-bar-wrapper predictive-search-box banner-sarch-wrapper" onClick={e => clickHandle()}>
               
                    <SearchListBox 
                        areaVal={areaVal} 
                        placeholder={"City, community or area"} 
                        setAreaVal={setAreaVal}
                        setAreasList={setAreasList} 
                        handleMultiAreaFun={handleMultiAreaFun} 
                        autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                    />
                </div>
                {props.alias === "commercial"?
                   <>
                    <div className="search-bedrooms-wrapper d-xl-flex align-items-center d-none" onClick={e => clickHandle()}>
                    <div class="search-divider"></div>
                    <Select
                    options={parseInt(selectedMaxSqftValue) === 0 ? sqftMinRange : minSqft}
                    isSearchable={false} 
                    placeholder={minSqftVal ? minSqftVal : "Min Size"}
                    className={"select-control"}
                    classNamePrefix={"react-select"}
                    styles={customStyles}
                    value={sqftMinRange.find(obj => obj.value === minSqftVal)}
                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                    onChange={(e) => {
                        onChangeMinSqft(e)
                        setMinSqftVal(e.value)
                        }}/>
                    </div>
                    <div className="search-bedrooms-wrapper d-xl-flex align-items-center d-none" onClick={e => clickHandle()}>
                    <div class="search-divider"></div>
                    <Select
                        options={parseInt(selectedMinSqftValue) === 0 ? sqftMaxRange :maxSqft}
                        isSearchable={false}
                        placeholder={maxSqftVal ? maxSqftVal : "Max Size"}
                        className={"select-control"}
                        classNamePrefix={"react-select"}
                        styles={customStyles}
                        value={sqftMaxRange.find(obj => obj.value === maxSqftVal)}
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={(e) => {
                            onChangeMaxSqft(e)
                            setMaxSqftVal(e.value)
                            }}
                        />         
                    </div>
                    </>
                    :
                    <div className="search-bedrooms-wrapper d-xl-flex align-items-center d-none" onClick={e => clickHandle()}>
                    <div class="search-divider"></div>
                    <Select
                        options={bedroomsRange}
                        isSearchable={false}
                        placeholder={bedVal ? bedVal : "Bedrooms"}
                        className={"select-control"}
                        classNamePrefix={"react-select"}
                        styles={customStyles}
                        value={bedroomsRange.find(obj => obj.value === bedVal)}
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={e => setBedVal(e.value)}
                    />
                    </div>
                }
               
                <div className="search-price-wrapper pe-2 d-xl-block d-none">
                    <ul className="filter-list">
                        <li
                            className="price-filter-dropdown d-flex align-items-center"
                            id="filter-price-range"
                        >
                            <div class="search-divider"></div>
                            <button
                                type="button"
                                onClick={clickHandle}
                                className="d-flex align-items-center"
                            >
                                <div className="price-range">
                                    Price Range
                                    <i className="icon icon-select-dropdown"></i>
                                </div>
                            </button>
                            <div className="price-filter">
                                <Select
                                    options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : minPrice}
                                    isSearchable={false}
                                    placeholder="Min Price"
                                    placeholderValue={minpriceVal ? "AED"+minpriceVal : "Min Price"}
                                    classNamePrefix={"select-opt"}
                                    className="select-control price-dropdown"
                                    styles={customStyles}
                                    value={minPriceList.find(obj => obj.value === minpriceVal)}
                                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                    onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                />
                                <Select
                                    options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : maxPrice}
                                    isSearchable={false}
                                    placeholder="Max Price"
                                    placeholderValue={maxpriceVal ? "AED"+maxpriceVal : "Max Price"}
                                    classNamePrefix={"select-opt"}
                                    className="select-control price-dropdown"
                                    styles={customStyles}
                                    value={maxPriceList.find(obj => obj.value === maxpriceVal)}
                                    components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                    onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="search-property-wrapper d-xl-flex align-items-center d-none" onClick={e => clickHandle()}>
                    <div class="search-divider"></div>
                    <Select
                        options={props.alias === "commercial" ? comPropertyType : resiSalesPropertyType}
                        isSearchable={false}
                        placeholder={propertyval ? propertyval : "Property Type"}
                        className={"select-control"}
                        classNamePrefix={"react-select"}
                        styles={customStyles}
                        value={props.alias === "commercial" ? comPropertyType.find(obj => obj.value === propertyval) : resiSalesPropertyType.find(obj => obj.value === propertyval)} 
                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                        onChange={e => setPropertyVal(e.value)}
                    />
                </div>
                <div className="search-btn-wrapper">
                    <button className="search-btn" onClick={submitSearch}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                </div>
            </div>
            <div className="predictive-arelist-suggestion home-banner">
                <AreaListBox areas={areasArr} removeArea={removeArea} />
            </div>
        </section>
    )
}

export default SearchForm